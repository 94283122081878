<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.accumulatedProfitHistory')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <CInput :label="$t('pages.orders.orderNumber')" :placeholder="$t('pages.orders.enterorderNumber')" v-model="searchInfo.orderNumber" horizontal></CInput>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">                                
                            </CCol>
                        </CRow>                       
                    </CForm>

                </template>
                <template v-slot:list>
                    <CDataTable :items="accumulatedProfitList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100] }">
                        <template #footer v-if="accumulatedProfitList != null && accumulatedProfitList.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="7">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{accumulatedProfitList == null ? 0: accumulatedProfitList.length}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #amount_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amount)" />
                            </td>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                    </CDataTable>
                    
                </template>
            </ListForm>          
        </CCol>
    </CRow>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'   
    import ListForm from '@/components/ListForm.vue'   
    
    export default {
        name: 'Accumulated-Profit',
        data() {
            return {                                             
                itemsPerPage: 10,
                warningModal: false,
                fields: [                   
                    { key: 'orderId', label: i18n.t('pages.orders.orderNumber') },
                    { key: 'commissionRate', label: i18n.t('common.commissionRate') },
                    { key: 'amount_text', label: i18n.t('common.amount') },
                    { key: 'createdDate_text', label: i18n.t('common.createdDate') }
                ],
                startDate: null
            };
        },
        components: {
            ListForm
        },
        computed: {            
            ...mapState('accumulatedProfit', ['searchInfo', 'isLoading', 'accumulatedProfitList', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('accumulatedProfit', ['searchAccumulatedProfit']),

            formatDate(date) {
                if (date === undefined)
                    return '';

                let newDate = this.$moment(date, 'YYYY-MM-DD HH:mm:ss');

                if (!newDate.isValid())
                    newDate = this.$moment(date);

                return this.$moment(newDate).format('DD/MM/YYYY HH:mm:ss');
            },            
            onSearch() {                
                this.searchAccumulatedProfit();
            },            
            onItemsPerPageChange(item) {
                localStorage.setItem('accumulatedProfitItemsPerPage', item)
            },
        },
       
        created() {                       
            this.onSearch();

            let accumulatedProfitItemsPerPage = parseInt(localStorage.getItem('accumulatedProfitItemsPerPage'));
            if (isNaN(accumulatedProfitItemsPerPage))
                accumulatedProfitItemsPerPage = 10;
            this.itemsPerPage = accumulatedProfitItemsPerPage;
        }
	}
</script>

